import { BILLING_ASSOCIATE, PROJECT_COORDINATOR, PROVISIONING, SERVICE_MANAGER, SUPER_USER, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER } from '../administrators/api';

export enum WebexPolicies {
    CanManageAssignments = 'CanManageWebexAssignments',
    CanCancelAssignments = 'CanCancelWebexAssignments',
    CanUseBulkUpload = 'CanUseWebexBulkUpload',
    CanUseRedSkyTool = 'CanUseWebexRedSkyTool'
}

export const WebexPolicyRoles = {
    [WebexPolicies.CanManageAssignments]: [SUPER_USER, BILLING_ASSOCIATE, SERVICE_MANAGER, PROVISIONING],
    [WebexPolicies.CanCancelAssignments]: [SUPER_USER, SERVICE_MANAGER, PROVISIONING],
    [WebexPolicies.CanUseBulkUpload]: [SUPER_USER, SERVICE_MANAGER, PROJECT_COORDINATOR, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER, PROVISIONING],
    [WebexPolicies.CanUseRedSkyTool]: [SUPER_USER, SERVICE_MANAGER, PROJECT_COORDINATOR, SUPPORT_TECHNICIAN, SYSTEMS_ENGINEER, PROVISIONING],
};