import { FormControl, FormControlLabel, FormHelperText, Grid, Input, InputLabel, LinearProgress, Radio, RadioGroup, Select, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FieldError } from '../../../api';
import { STATES } from '../../../constants';
import { useValidationHelpers } from '../../../form-helpers';
import theme from '../../../theme';
import { OrganizationDetails } from '../../organizations/api';
import { useFetchWebexBandwidthSubAccounts } from '../bandwidth-subaccounts/hooks';
import { useFetchWebexCustomers } from '../customers/hooks';
import { useFetchWebexDubberAccounts } from '../dubber-accounts/hooks';
import { CreateAssignment } from './api';
import { webexIdToUuid } from '../helpers';

const useStyles = makeStyles(() => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

interface Props {
    organization: OrganizationDetails;
    assignmentId?: number;
    formValues: CreateAssignment,
    isSubmitting: boolean;
    errorMessage: string | null;
    fieldErrorMessages: FieldError[];
    setFormValues: (form: CreateAssignment) => void;
}

export const formComplete = (organization: OrganizationDetails, form: CreateAssignment): boolean => {
    const addressFieldsComplete =
        form.useExisting
            ? (
                (form.webexCustomerId || '').length > 0
                && (form.bandwidthSubAccount || '').length > 0
                && (form.dubberAccount || '').length > 0
            )
            : (
                (form.accountName || '').length > 0
                && (form.locationName || '').length > 0
                && (form.houseNumber || '').length > 0
                && (form.streetName || '').length > 0
                && (form.city || '').length > 0
                && (form.state || '').length > 0
                && (form.zipCode || '').length > 0
                && (form.timeZone || '').length > 0
                && (form.administratorEmail || '').length > 0
            );

    return addressFieldsComplete;
};

/**
 * Webex create assignment form
 * @param props
 * @constructor
 */
const CreateAssignmentForm = (props: Props) => {
    const { organization, errorMessage, fieldErrorMessages, formValues, setFormValues, isSubmitting } = props;
    const classes = useStyles();

    const { isValid, ValidationMessage } = useValidationHelpers(fieldErrorMessages);

    const [fetchWebexCustomers, isFetchingWebexCustomers, webexCustomers, webexCustomersError] = useFetchWebexCustomers();
    const [fetchBandwidthSubAccounts, isFetchingBandwidthSubAccounts, bandwidthSubAccounts, bandwidthSubAccountsError] = useFetchWebexBandwidthSubAccounts();
    const [fetchDubberAccounts, isFetchingDubberAccounts, dubberAccounts, dubberAccountsError] = useFetchWebexDubberAccounts();

    useEffect(() => {
        fetchWebexCustomers();
        fetchBandwidthSubAccounts();
        fetchDubberAccounts();
    }, [])

    // Set form values once organization details & accounts are loaded
    useEffect(() => {
        if (organization) {
            const address = (organization.address || '').split(' ');
            let houseNumber = '';
            let streetName = '';

            if (address.length > 0) {
                address.reverse();
                houseNumber = address.pop() || '';

                streetName = address.reverse().join(' ');
            }

            setFormValues({
                ...formValues,
                accountName: organization.name,
                locationName: organization.city || '',
                houseNumber: houseNumber,
                streetName: streetName,
                addressLine2: organization.address2 || '',
                city: organization.city || '',
                state: organization.state || '',
                zipCode: organization.zip || ''
            });
        }
    }, [organization]);

    const handleFieldChange = (fieldName: keyof CreateAssignment, value: string) => {
        setFormValues({
            ...formValues,
            [fieldName]: value
        });
    };

    const handleWebexCustomerChange = (customerId: string) => {
        var dubberAccountId = "";

        if (webexCustomers && dubberAccounts) {
            var webexCustomer = webexCustomers.find(c => c.id == customerId);

            if (webexCustomer) {
                var requiredDubberAccountId = webexIdToUuid(webexCustomer.orgId).toLocaleLowerCase().replace(/-/g, "");

                // Does Dubber account actually exist? if so, select it
                var dubberAccount = dubberAccounts.find(a => a.id == requiredDubberAccountId);

                if (dubberAccount) {
                    dubberAccountId = dubberAccount.id;
                }
            }
        }

        setFormValues({
            ...formValues,
            webexCustomerId: customerId,
            dubberAccount: dubberAccountId
        })
    };

    // Order webex orgs by name
    const orderedWebexCustomerOptions = useMemo(() => {
        if (webexCustomers === null) {
            return null;
        }

        return webexCustomers.sort((a, b) => a.name.localeCompare(b.name))
    }, [webexCustomers]);

    const displayError = errorMessage || webexCustomersError || bandwidthSubAccountsError || dubberAccountsError;

    return (
        <>
            {!isFetchingWebexCustomers
                && !isFetchingBandwidthSubAccounts
                && !isFetchingDubberAccounts
                && orderedWebexCustomerOptions !== null
                && bandwidthSubAccounts !== null
                && dubberAccounts !== null
                ?
                <>
                    {displayError && <Typography variant="body1" color="error">{displayError}</Typography>}

                    <FormControl className={classes.formControl} fullWidth required>
                        <RadioGroup
                            name="useExisting"
                            value={formValues.useExisting ? '1' : '0'}
                            onChange={(evt, val) => setFormValues({ ...formValues, useExisting: val === '1' })}>

                            <FormControlLabel
                                value="0"
                                control={<Radio />}
                                disabled={true || isSubmitting}
                                label={"Create New Accounts"}
                            />

                            <FormControlLabel
                                value="1"
                                control={<Radio />}
                                disabled={isSubmitting}
                                label={"Use Existing Accounts"}
                            />
                        </RadioGroup>
                    </FormControl>

                    {!formValues.useExisting &&
                        <>
                            <FormControl className={classes.formControl} fullWidth required error={!isValid('accountName')}>
                                <InputLabel htmlFor="accountName">Account Name</InputLabel>

                                <Input
                                    autoFocus
                                    value={formValues.accountName || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => handleFieldChange('accountName', evt.target.value)}
                                    id="accountName"
                                    type="text" />

                                <ValidationMessage field="accountName" />
                            </FormControl>

                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm={2}>
                                    <FormControl className={classes.formControl} fullWidth required error={!isValid('houseNumber')}>
                                        <InputLabel htmlFor="houseNumber">House Number</InputLabel>

                                        <Input
                                            value={formValues.houseNumber || ''}
                                            disabled={isSubmitting}
                                            onChange={evt => handleFieldChange('houseNumber', evt.target.value)}
                                            id="houseNumber"
                                            type="text" />

                                        <ValidationMessage field="houseNumber" />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={10}>
                                    <FormControl className={classes.formControl} fullWidth required error={!isValid('streetName')}>
                                        <InputLabel htmlFor="streetName">Street Name</InputLabel>

                                        <Input
                                            value={formValues.streetName || ''}
                                            disabled={isSubmitting}
                                            onChange={evt => handleFieldChange('streetName', evt.target.value)}
                                            id="streetName"
                                            type="text" />

                                        <ValidationMessage field="streetName" />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <FormControl className={classes.formControl} fullWidth error={!isValid('addressLine2')}>
                                <InputLabel htmlFor="addressLine2">Address Line 2</InputLabel>

                                <Input
                                    value={formValues.addressLine2 || ''}
                                    disabled={isSubmitting}
                                    onChange={evt => handleFieldChange('addressLine2', evt.target.value)}
                                    id="addressLine2"
                                    type="text" />

                                <ValidationMessage field="addressLine2" />
                            </FormControl>

                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl className={classes.formControl} fullWidth required error={!isValid('city')}>
                                        <InputLabel htmlFor="city">City</InputLabel>

                                        <Input
                                            value={formValues.city || ''}
                                            disabled={isSubmitting}
                                            onChange={evt => handleFieldChange('city', evt.target.value)}
                                            id="city"
                                            type="text" />

                                        <ValidationMessage field="city" />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} sm={4}>
                                    <FormControl className={classes.formControl} fullWidth required error={!isValid('state')}>
                                        <InputLabel htmlFor="state">State</InputLabel>

                                        <Select
                                            id="state"
                                            native={true}
                                            margin="none"
                                            disabled={isSubmitting}
                                            value={formValues.state || ''}
                                            input={<Input name="state" id="state" />}
                                            onChange={evt => handleFieldChange('state', evt.target.value)}>
                                            {StateOptions()}
                                        </Select>

                                        <ValidationMessage field="state" />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6} sm={2}>
                                    <FormControl className={classes.formControl} fullWidth required error={!isValid('zipCode')}>
                                        <InputLabel htmlFor="zipCode">Zip Code</InputLabel>

                                        <Input
                                            value={formValues.zipCode || ''}
                                            disabled={isSubmitting}
                                            onChange={evt => handleFieldChange('zipCode', evt.target.value)}
                                            id="zipCode"
                                            type="text" />

                                        <ValidationMessage field="zipCode" />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </>
                    }

                    {formValues.useExisting &&
                        <>
                            <FormControl className={classes.formControl} fullWidth required error={!isValid('webexCustomerUuid')}>
                                <InputLabel htmlFor="webexCustomerUuid">Webex Organization</InputLabel>

                                <Select
                                    id="webexCustomerUuid"
                                    margin="none"
                                    native={true}
                                    disabled={isSubmitting}
                                    value={formValues.webexCustomerId || ''}
                                    onChange={evt => handleWebexCustomerChange(evt.target.value)}
                                    input={<Input name="webexCustomerUuid" id="webexCustomerUuid" />}>

                                    <option key="" value="" />

                                    {webexCustomers.map(grp => <option key={grp.id}
                                        value={grp.id}>{grp.name} ({webexIdToUuid(grp.orgId)})</option>)}
                                </Select>

                                <ValidationMessage field="webexCustomerUuid" />
                            </FormControl>

                            <FormControl className={classes.formControl} fullWidth required error={!isValid('bandwidthSubAccount')}>
                                <InputLabel htmlFor="bandwidthSubAccount">Bandwidth Sub-Account</InputLabel>

                                <Select
                                    id="bandwidthSubAccount"
                                    margin="none"
                                    native={true}
                                    disabled={isSubmitting}
                                    value={formValues.bandwidthSubAccount || ''}
                                    onChange={evt => setFormValues({ ...formValues, bandwidthSubAccount: evt.target.value })}
                                    input={<Input name="bandwidthSubAccount" id="bandwidthSubAccount" />}>

                                    <option key="" value="" />

                                    {bandwidthSubAccounts.map(acct => <option key={acct.id} value={acct.id}>{acct.name} ({acct.id})</option>)}
                                </Select>

                                <ValidationMessage field="bandwidthSubAccount" />
                            </FormControl>

                            <FormControl className={classes.formControl} fullWidth error={!isValid('dubberAccount')}>
                                <InputLabel htmlFor="dubberAccount" shrink={true}>Dubber Account</InputLabel>

                                <Input
                                    disabled={isSubmitting}
                                    readOnly={true}
                                    id="dubberAccount"
                                    value={formValues.webexCustomerId
                                        ? (!formValues.dubberAccount
                                            ? "No Account Found"
                                            : `${dubberAccounts.find(a => a.id == formValues.dubberAccount)?.name} (${dubberAccounts.find(a => a.id == formValues.dubberAccount)?.id})`
                                        ) : ""}
                                    type="text" />

                                <FormHelperText>Account will be automatically selected based on Webex Organization</FormHelperText>

                                <ValidationMessage field="dubberAccount" />
                            </FormControl>
                        </>
                    }
                </>
                : <LinearProgress />
            }
        </>
    );
};

// Dropdown options for State field
const StateOptions = () => {
    let entries = [<option key="" value="" />];

    for (const key in STATES) {
        entries.push(<option key={key} value={key}>{STATES[key]}</option>);
    }

    return entries;
};

export default CreateAssignmentForm;