import { ApiCaller, PagedResponse } from '../../../api';

export interface WebexProductCounts {
    hasContracted: boolean;
    totals: WebexProductCount[];
    deployed: WebexLocationProductCount[];
    contracted: WebexLocationProductCount[];
}

export type WebexProduct = 'WebexCalling' | 'WebexVoice' | 'WebexMeetings' | 'WebexSuite' | 'CommonAreaCalling' | 'CustomerExperienceEssentials';

export interface WebexProductCount {
    product: WebexProduct;
    deployed: number;
    contracted: number;
}

export interface WebexLocationProductCount {
    location: string;
    product: WebexProduct;
    count: number;
}


export const fetchWebexProductCounts = async (api: ApiCaller, organizationId: number, assignmentId: number): Promise<WebexProductCounts> => {
    return await api<WebexProductCounts>(`organizations/${organizationId}/services/webex/${assignmentId}/products`, 'GET');
};