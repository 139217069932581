import { LinearProgress, Link, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useApi } from '../../../api';
import PageHeader from '../../../components/PageHeader';
import { MAIN_WIDTH } from '../../../constants';
import { appWindowAddNotification } from '../../app-window/actions';
import { useNav, useProgressEffects } from '../../app-window/hooks';
import { withPolicyRestriction } from '../../auth/policies';
import { fetchBulkUploadDownloadUrl } from '../api';
import { useFetchAllHpbxOrganizations } from '../assignment/hooks';
import { HostedPbxOrganizationForm } from '../components/HostedPbxOrganizationForm';
import { HostedPbxPolicies } from '../policies';
import { UploadForm } from './UploadForm';
import { UploadTable } from './UploadTable';
import theme from '../../../theme';
import { ErrorModal } from './ErrorModal';
import { useFetchBulkUploads } from './hooks';

interface Props extends RouteComponentProps<any> {
}

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: MAIN_WIDTH,
        margin: 'auto'
    },
    subHeading: {
        marginBottom: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    },
    ftpWarning: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    }
}));

/**
 * Hook for allowing downloads of password files
 */
const useDownload = () => {
    let didCancel = false;
    const api = useApi();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            didCancel = true;
        }
    }, []);

    return useCallback(async (organizationId: number, assignmentId: number, uploadId: number) => {
        dispatch(appWindowAddNotification('Starting download', 'success'));

        try {
            const downloadUrl = await fetchBulkUploadDownloadUrl(api, organizationId, assignmentId, uploadId);
            window.open(downloadUrl);
        } catch (e) {
            if (!didCancel) {
                dispatch(appWindowAddNotification('Download failed', 'error'));
            }
        }

    }, []);
};

/**
 * Bulk Tool Upload index page
 */
const BulkUploadIndexPage = (props: Props) => {
    const classes = useStyles();

    const [isFetchingOrganizations, organizations, fetchOrganizationsError] = useFetchAllHpbxOrganizations('active');

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | null>(null);
    const [selectedAssignmentId, setSelectedAssignmentId] = useState<number | null>(null);

    // Modal for showing upload errors
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorModalTitle, setErrorModalTitle] = useState('Error Messages');
    const [errorModalErrors, setErrorModalErrors] = useState<string[]>([]);

    const {
        fetch,
        isFetching: isFetchingUploads,
        uploads,
        error: fetchUploadsError,
    } = useFetchBulkUploads();

    const download = useDownload();

    useNav('hosted-pbx', 'bulk-uploads');

    useProgressEffects(isFetchingOrganizations, fetchOrganizationsError || fetchUploadsError);

    // When an upload is complete (successful or not), the Recent Uploads table is refreshed
    const refreshRecentUploads = () => {
        if (selectedOrganizationId != null && selectedAssignmentId != null) {
            fetch(selectedOrganizationId, selectedAssignmentId, 'uploadedAt', 'desc', 1);
        }
    };

    const selectedOrganization = useMemo(() => {
        if (organizations == null) {
            return null;
        } else {
            return organizations.find(o => o.assignmentId == selectedAssignmentId);
        }
    }, [selectedOrganizationId, selectedAssignmentId]);

    useEffect(() => {
        // Refresh recent uploads when the organization is changed
        refreshRecentUploads();
    }, [selectedOrganizationId, selectedAssignmentId]);


    const showErrors = (uploadId: number) => {
        // Find upload details for given upload ID
        if (!isFetchingUploads && uploads !== null) {
            var upload = uploads.find(x => x.id === uploadId);

            if (upload) {
                setErrorModalTitle('Error Messages');
                setErrorModalErrors((upload.errorMessage || '').split("\n"));
                setErrorModalOpen(true);
            }
        }
    };

    const showZtpWarnings = (uploadId: number) => {
        // Find upload details for given upload ID
        if (!isFetchingUploads && uploads !== null) {
            var upload = uploads.find(x => x.id === uploadId);

            if (upload) {
                setErrorModalTitle('ZTP Warnings');
                setErrorModalErrors((upload.ztpErrorMessages || '').split("\n"));
                setErrorModalOpen(true);
            }
        }
    };

    return (
        <div className={classes.root}>
            <PageHeader text="Hosted PBX" subtext="Bulk Upload Tool" />

            {!isFetchingOrganizations && organizations !== null &&
                <Paper className={classes.paper}>
                    <HostedPbxOrganizationForm
                        organizations={organizations}
                        selectedOrganizationId={selectedOrganizationId}
                        selectedAssignmentId={selectedAssignmentId}
                        onSelect={(orgId, asId) => {
                            setSelectedOrganizationId(orgId);
                            setSelectedAssignmentId(asId);
                        }
                        } />
                </Paper>
            }

            {selectedOrganizationId !== null
                && selectedAssignmentId !== null
                && <>
                    {selectedOrganization && selectedOrganization.phoneConfigurationMethod === 'FTP' && <>
                        <Paper className={classes.paper}>
                            <div className={classes.ftpWarning}>
                                <Typography color="textSecondary" align="center">
                                    ZTP will not be enabled for uploaded devices because the organization uses legacy FTP phone configurations.
                                </Typography>
                            </div>
                        </Paper>
                    </>
                    }


                    <Typography variant="h3" className={classes.subHeading}>Upload</Typography>

                    <Paper className={classes.paper}>
                        <Typography variant="body1"><Link href="/documents/bulk-upload-1.11.0.xlsx" target="_blank">Latest template (1.11.0)</Link></Typography>

                        <UploadForm
                            organizationId={selectedOrganizationId}
                            assignmentId={selectedAssignmentId}
                            onComplete={() => refreshRecentUploads()}
                            onDownloadPasswords={uploadId => download(selectedOrganizationId, selectedAssignmentId, uploadId)}
                            onShowErrors={uploadId => showErrors(uploadId)}
                            onShowZtpWarnings={uploadId => showZtpWarnings(uploadId)}
                        />
                    </Paper>

                    <Typography variant="h3" className={classes.subHeading}>Recent Uploads</Typography>

                    {!isFetchingUploads && uploads !== null
                        ? <Paper className={classes.paper}>
                            <UploadTable
                                uploads={uploads}
                                onShowErrors={uploadId => showErrors(uploadId)}
                                onShowZtpWarnings={uploadId => showZtpWarnings(uploadId)}
                                onDownloadPasswords={uploadId => download(selectedOrganizationId, selectedAssignmentId, uploadId)} />
                        </Paper>
                        : <LinearProgress variant="indeterminate" color="primary" />}
                </>
            }

            <ErrorModal title={errorModalTitle} open={errorModalOpen} errors={errorModalErrors} onClose={() => setErrorModalOpen(false)} />
        </div>
    );
};

export default withPolicyRestriction(BulkUploadIndexPage, HostedPbxPolicies.CanUseBulkUpload);